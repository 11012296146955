import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setMessage } from "../elements/alert/Action";
import { requestData } from "../helpers/requestData";
import { updateParticipantFromClientAction } from "../../actions/participant";
const Home = (props) => {

    let [updateParticipantFromClient, setUpdateParticipantFromClient] = useState();
    let [content, setContent] = useState();

    // Get Data Query from URL Here
    // Check the vid is Present
    // call the create Participant API

    let allRequestData = requestData(window);

    

    let c1Search = getUrlParam('rid', 'Empty');
    let c2Search = getUrlParam('pid', 'Empty');

    useEffect(() => {
        if (props.alertMessage == "" && allRequestData.badUrlHitting) {
            props.setMessage({ success: false, message: "You are hitting a bad url." })
        };


    }, [props.alertMessage]);

    // Call the Create Participant API

    useEffect(() => {
        if (!updateParticipantFromClient && !allRequestData.badUrlHitting) {
            // call action of API
            let allQueryParams = allRequestData.urlQueryString;
            let landingURL = allRequestData.landingURL;
            setUpdateParticipantFromClient(true);
            props.updateParticipantFromClientAction(allQueryParams, landingURL);

        }
    })



    return (
        (c1Search.startsWith("SS") || c2Search.startsWith("SS") ) == 1 ? 
        <div>
            
        </div>
        :
        <>
            <div id="terminateDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
                <img src={process.env.PUBLIC_URL + '/completes-img.png'} alt="complete" style={{ width: "100%", height: "100%" }} />
                <div style={{ position: "absolute", top: "52%", left: "47%", transform: "translate(-50%, -50%)", zIndex: "10" }}>
                    <img src={process.env.PUBLIC_URL + '/spinner.png'} alt="loader" style={{ width: "150%", animation: "spin 2s linear infinite" }} />
                </div>
            </div>

            <div id="rejectDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
                <img src={process.env.PUBLIC_URL + '/Thanks_mobile.svg'} alt="terminate" style={{ width: "100%", height: "100%" }} />
                <div style={{ position: "absolute", top: "43%", left: "47%", transform: "translate(-50%, -50%)", zIndex: "10" }}>
                    <img src={process.env.PUBLIC_URL + '/spinner.png'} alt="loader" style={{ width: "150%", animation: "spin 2s linear infinite" }} />
                </div>
            </div>
        </>
    )
}

function getUrlParam(parameter, defaultvalue) {
    var urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = getUrlVars()[parameter];
    }
    return urlparameter;
}
  
function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
        vars[key] = value;
    });
    return vars;
}

const mapStateToProps = (state) => {
    return {
        alertMessage: state.alert.alertMessage
    }
}
export default connect(mapStateToProps, {
    setMessage, updateParticipantFromClientAction
})(Home);